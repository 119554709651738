<template>
  <div>
    <main>
      <!-- 1  v-lazy:background-image="require('./photo/bannerone.jpg')" -->
      <div class="banner">
        <img  v-lazy="require('./photo/u4gd4vVl.webp')">
        <!-- 横幅内容区域 -->
      <div class="qwe">
          <h2 class="title">智能联动</h2>
        <p class="introduce">
          将接入的各类信号进行综合分析及定型规划，第一时间获取信号，及时制定各种计划
        </p>
      </div>
        <div class="mork"></div>
      </div>
      <!-- 2 730 -->
      <div class="twobig">
        <div class="center_first">
          <h2>实现事件联动</h2>
          <p>
            远程了解景区人员部署情况，实现活动调用分配，紧急事件
          </p>
          <div class="img_first"></div>
        </div>
      </div>
      <!-- 3 720 -->
      <div class="threebg">
        <div class="textb">报警联动</div>
        <div class="texts">
          现代化一体式报警系统，及时有效处理突发事件。保证生命财产的安全
        </div>
      </div>
      <!-- 4  546px   -->
      <div class="fourbg">
        <div class="textb">监控联动</div>
        <div class="texts">全方位，清晰每个角落，扫进身边的安全隐患</div>
        <div class="imgfours"></div>
      </div>
      <!-- 5 720 -->
      <div class="fivebg">
        <div class="textwub">广播联动</div>
        <div class="textwuc">及时有效传递各路信息，汇总，发布</div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "znldd",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.banner {
  height: 600px;
  width: 100%;
  color: #fff;
  img[lazy="loading"] {
    margin-top: 150px;
		width: 200px;
		height: 200px;
	}
	// 实际图片大小
	img[lazy="loaded"] {
	height: 600px;
  width: 100%;
	}

  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  position: relative;
 .qwe{
    .title {
    z-index: 2;
    width: 412px;
    height: 67px;

    font-weight: 500;
    line-height: 67px;
    letter-spacing: 3px;
    position: absolute;
    left: 250px;
    top: 230px;
    font-size: 48px;

    font-weight: 500;
    color: #fff;
  }
  .introduce {
    z-index: 2;
    width: 697px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    text-align: left;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 360px;
    top: 317px;
  }
 }
}
.introduce,
.title {
  animation: sport 0.5s;
  transform: translateY(0px);
  width: 300px;
  height: 80px;
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.mork {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.63;
}
//2

.twobig {
  height: 780px;
  background-color: #fff;
  .center_first {
    padding-top: 80px;
    h2 {
      font-size: 40px;
      font-family: PingFangSC-Medium,  PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-bottom: 24px;
    }
    p {
      padding-bottom: 60px;
      font-size: 20px;
      font-family: PingFangSC-Regular,  PingFang SC;
      font-weight: 400;
      color: #565656;
    }
  }
  .img_first {
    width: 1200px;
    height: 420px;
    background: url("./photo/PZDu2U68.webp") no-repeat center;
    margin: 0 auto;
    background-size: 100% 100%;
  }
}
//3
.threebg {
  height: 720px;
  background: url("./photo/EsQlZqIP.webp") no-repeat center;
  background-size: 100% 100%;
  padding-top: 310px;
  .textb {
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 12px;
  }
  .texts {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
//4
.fourbg {
  height: 780px;
  padding-top: 80px;
  .textb {
    font-size: 40px;

    font-weight: 500;
    color: #333333;
    padding-bottom: 24px;
  }
  .texts {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #565656;
    padding-bottom: 60px;
  }
  .imgfours {
    width: 1200px;
    height: 420px;
    background: url("./photo/W2DcI904.webp") no-repeat center;
    background-size: 100% 100%;
    margin: 0 auto;
  }
}
//5
.fivebg {
  height: 720px;
  background: url("./photo/W4qJqqL8.webp") no-repeat center;
  background-size: 100% 100%;
  padding-top: 310px;
  .textwub {
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-bottom: 12px;
  }
  .textwuc {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
